var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-notice-bar",
        {
          attrs: {
            color: "#1989fa",
            background: "#ecf9ff",
            "left-icon": "info-o",
          },
        },
        [_vm._v(" 行程概览 ")]
      ),
      _c("div", { staticClass: "Resource" }, [
        _c("div", { staticClass: "Resource-Box" }, [
          _c("div", { staticClass: "Resource-Box-Top" }, [
            _c("div", { staticClass: "Resource-Box-Top-Left" }, [
              _c("h3", [_vm._v(_vm._s(_vm.dayJs()))]),
              _c("span", [_vm._v("DAY")]),
            ]),
            _c("div", { staticClass: "Resource-Box-Top-Right" }, [
              _c("h4", [_vm._v(_vm._s(_vm.planDayMap.cityStr))]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("dateFormatPoint99")(_vm.planDayMap.planDate))
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "Resource-Box-Bottom" },
            _vm._l(_vm.planDayMap.budgetList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "Resource-Box-Bottom-Content" },
                [
                  _c("h3", [_vm._v(_vm._s(item.resourceNameCn))]),
                  _c(
                    "div",
                    { staticClass: "Resource-Box-Bottom-BriefIntroduction" },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#B3B3B3",
                            "margin-top": "5px",
                          },
                        },
                        [
                          _c("van-icon", { attrs: { name: "location-o" } }),
                          _vm._v(" " + _vm._s(item.addressCn) + " "),
                        ],
                        1
                      ),
                      _c("p", { staticStyle: { "margin-top": "5px" } }, [
                        _vm._v(" " + _vm._s(item.introduce) + " "),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }