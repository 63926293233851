<template>
    <van-tabs v-model:active="active" @change="changeDay">
        <van-tab v-for="(item,index) in planDayList" :key="index" :title="'第' + item.planDay + '天'">
            <planDaysSlider v-if="active === index" :planDayList="planDayList" :planDayMap="item" :day="item.planDay"></planDaysSlider>
        </van-tab>
    </van-tabs>
</template>

<script>
import {changePlanInfo} from "@/views/plan/model/PlanInfo";
import {mapMutations, mapState} from "vuex";
import planDetailMap from "@/views/plan/children/PlanDetailMap.vue";
import planDaysSlider from "@/views/plan/children/PlanDaysSlider.vue";
export default {
    name: "PlanDays",
    data() {
        return {
            planDayList: [],
            active: 0
        }
    },
    computed: {
        ...mapState('collection', ['dayData','budgetMainId','planId','checkDay']),
    },
    components: {planDaysSlider},
    mounted() {
        this.schemeData()
        this.active = this.dayData.planDay - 1
    },
    methods: {
        ...mapMutations('collection',['SET_CHECK_DAY']),
        changeDay(e) {
            this.SET_CHECK_DAY(e + 1)
        },
        schemeData() {
            changePlanInfo({ planId: this.planId, budgetMainId: this.budgetMainId }).then(pro => {
                console.log('schemeData',pro)
                this.planDayList = pro._platDays
            })
        },
    }
}
</script>

<style scoped>

</style>
