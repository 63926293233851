<template>
    <div>
        <van-sidebar @change="sidebarChange" v-model="active" style="margin-top: 87px">
            <van-sidebar-item v-for="(state,stateIndex) in stateListMap" :key="stateIndex">
                <template slot="title">
                    <img style="width: 15px;height: 15px" :src="state.pic"></img>
                    {{state.name}}
                </template>
            </van-sidebar-item>
        </van-sidebar>
        <planDetailMap :key="active" :planDayList="planDayList" :state="state" :plan-day-map="planDayMap"></planDetailMap>
        <BudgetStateDetail :plan-day-map="planDayMap"></BudgetStateDetail>
    </div>
</template>

<script>
import planDetailMap from "@/views/plan/children/PlanDetailMap.vue";
import {groupBy} from "@/utils/arrayGroup";
import {typeNemeList1} from "@/utils/unit";
import BudgetStateDetail from "@/views/plan/children/BudgetStateDetail.vue";

export default {
    name: "PlanDaysSlider",
    data() {
        return {
            active: 1,
            state: -1,
            stateListMap: [],
            typeName: typeNemeList1
        }
    },
    methods: {
        sidebarChange(e) {
            this.stateListMap = []
            this.stateListMap.push({code: -2,name: '全览',pic: 'https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E6%89%80%E5%9C%A8%E5%9F%8E%E5%B8%82.png'})
            this.stateListMap.push({code: -1,name: '城市',pic: 'https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E6%89%80%E5%9C%A8%E5%9F%8E%E5%B8%82.png'})
            let arr = groupBy(this.planDayMap.budgetList, 'state');
            for (let groupByKey in arr) {
                if (this.typeName[groupByKey]) {
                    this.stateListMap.push({code: this.typeName[groupByKey]?.code, name: this.typeName[groupByKey]?.name, pic: this.typeName[groupByKey]?.pic})
                }
            }
            this.state = this.stateListMap[e].code
        }
    },
    props: {
        'day': {
            type: Number,
            default: () => {
                return 0
            }
        },
        'planDayMap': {
            type: Object,
            default: () => {
                return {}
            }
        },
        'planDayList': {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    mounted() {
        this.myDay = this.day
        this.sidebarChange(1)
    },
    components: {planDetailMap,BudgetStateDetail},
    beforeDestroy() {
    },
    // 生命周期 - 销毁完成
    destroyed() {
    },
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {
    },
    //离开的时候触发
    deactivated() {
    },
}
</script>

<style scoped lang="scss">
.van-sidebar {
    width: 80px;
    position: absolute;
    z-index: 1000;
    .van-sidebar-item {
        //background: rgba(255, 255, 255, 0);
        color: #2DC4BB;
        padding: 12px 12px;
    }
}
</style>
