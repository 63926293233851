var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-tabs",
    {
      on: { change: _vm.changeDay },
      model: {
        value: _vm.active,
        callback: function ($$v) {
          _vm.active = $$v
        },
        expression: "active",
      },
    },
    _vm._l(_vm.planDayList, function (item, index) {
      return _c(
        "van-tab",
        { key: index, attrs: { title: "第" + item.planDay + "天" } },
        [
          _vm.active === index
            ? _c("planDaysSlider", {
                attrs: {
                  planDayList: _vm.planDayList,
                  planDayMap: item,
                  day: item.planDay,
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }