<template>
    <div>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        行程概览
      </van-notice-bar>
      <div class="Resource">
         <div class="Resource-Box">
             <div class="Resource-Box-Top">
                 <div class="Resource-Box-Top-Left">
                     <h3>{{dayJs()}}</h3>
                     <span>DAY</span>
                 </div>
                 <div class="Resource-Box-Top-Right">
                     <h4>{{planDayMap.cityStr}}</h4>
                     <span>{{planDayMap.planDate | dateFormatPoint99}}</span>
                 </div>
             </div>
             <div class="Resource-Box-Bottom">
                 <div v-for="(item,index) in planDayMap.budgetList" :key="index" class="Resource-Box-Bottom-Content">
                     <h3>{{item.resourceNameCn}}</h3>
                     <div class="Resource-Box-Bottom-BriefIntroduction">
                         <p style="font-size: 12px;color: #B3B3B3;margin-top: 5px">
                             <van-icon name="location-o" />
                             {{item.addressCn}}
                         </p>
                         <p style="margin-top: 5px">
<!--                             {{item.introduction}}-->
                             {{item.introduce}}
                         </p>
                     </div>
                 </div>
             </div>
         </div>
      </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
    name: "BudgetStateDetail",
    data() {
        // 这里存放数据
        return {}
    },
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    props: {
        'planDayMap': {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    // 方法集合
    methods: {},
    // 计算属性 类似于 data 概念
    computed: {
        dayJs() {
            return function () {
                if (this.planDayMap.planDay < 10) {
                    return "0" + this.planDayMap.planDay
                }
                return this.planDayMap.planDay
            }
        }
    },
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {
    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
    },
    // 生命周期 - 挂载之前
    beforeMount() {
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
    },
    // 生命周期 - 更新之前
    beforeUpdate() {
    },
    // 生命周期 - 更新之后
    updated() {
    },
    // 生命周期 - 销毁之前
    beforeDestroy() {
    },
    // 生命周期 - 销毁完成
    destroyed() {
    },
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {
    },
    //离开的时候触发
    deactivated() {
    },
}
</script>

<style scoped lang="scss">
.Resource {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    .Resource-Box {
        margin-top: 10px;
        width: 98%;
        border-radius: 10px;
        border: 1px green solid;
        min-height: 100px;
        .Resource-Box-Top {
            border-bottom: 1px green solid;
            padding: 10px;
            .Resource-Box-Top-Left {
                display: inline-block;
                border-right: 3px black solid;
                padding-right: 10px;
            }
            .Resource-Box-Top-Right {
                display: inline-block;
                padding-left: 10px;
            }
        }
        .Resource-Box-Bottom {
            padding: 10px;
            .Resource-Box-Bottom-Content {
                border: 1px green solid;
                border-radius: 10px;
                padding: 10px;
                margin-bottom: 10px;
            }
        }
    }
}
</style>
